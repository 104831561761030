import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { Modal, Form, Wrapper } from '../../components';
import ReportService from '../../api/services/ReportService';

const RequestReportModal = ({
  isOpen,
  selectedReport,
  onClose,
  refetchReports,
  refetchRequests,
}) => {
  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
  const [startDate, setStartDate] = useState(defaultStartDate.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);

  const sendRequestButtonHandler = async (reportName, reportStartDate, reportEndDate) => {
    try {
      await ReportService.sendReportRequest(reportName, reportStartDate, reportEndDate);
      toastr.success('Request sent successfully!');
      refetchReports();
      refetchRequests();
      onClose();
    } catch {
      toastr.error('Failed to send request.');
    }
  };

  if (!isOpen || !selectedReport) return null;

  return (
    <Modal
      header={{
        title: 'Request Report',
        subTitle: 'Provide necessary details to request this report.',
      }}
      open={isOpen}
      onClose={onClose}
      wide
    >
      <Modal.ClosableBody>
        {({ closePopup }) => (
          <Form
            initialValues={{}}
            onSubmit={async () => {
              await sendRequestButtonHandler(selectedReport.name, startDate, endDate);
              closePopup();
            }}
          >
            {/* Bootstrap Grid System for 2-column layout */}
            <div className="row mb-3">
              <div className="col-md-6">
                <strong className="fw-bold">Selected Report:</strong>
                <p>{selectedReport.report_name_formated}</p>
              </div>
              <div className="col-md-6">
                <strong className="fw-bold">Description:</strong>
                <p>{selectedReport.description}</p>
              </div>
            </div>

            {selectedReport.data_range_required && (
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="startDate" className="form-label fw-bold">
                    Start Date
                  </label>
                  <input
                    id="startDate"
                    type="date"
                    className="form-control"
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="endDate" className="form-label fw-bold">
                    End Date
                  </label>
                  <input
                    id="endDate"
                    type="date"
                    className="form-control"
                    value={endDate}
                    onChange={e => setEndDate(e.target.value)}
                  />
                </div>
              </div>
            )}

            {/* Submit Button */}
            <Wrapper d-flex justify-content-end>
              <Form.Submit>Submit</Form.Submit>
            </Wrapper>
          </Form>
        )}
      </Modal.ClosableBody>
    </Modal>
  );
};

RequestReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectedReport: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  refetchReports: PropTypes.func.isRequired,
  refetchRequests: PropTypes.func.isRequired,
};

export default RequestReportModal;
