import { toastr } from 'react-redux-toastr';
import React from 'react';
import ReportService from '../../api/services/ReportService';
import { ReactComponent as SaveIcon } from '../../assets/svg/downloadFile.svg';

export const reportsTableColumns = prefixT => [
  {
    Header: prefixT('t1Name'),
    accessor: 'report_name_formated',
  },
  {
    Header: prefixT('t1Description'),
    accessor: 'description',
    sortable: false,
  },
  {
    Header: prefixT('t1RunningRequest'),
    accessor: 'last_request',
    sortable: false,
  },
];

export const statusTableColumns = prefixT => [
  {
    Header: prefixT('rowTitle'),
    accessor: 'report_name_formated',
    sortable: false,
  },
  {
    Header: 'Request time',
    accessor: 'requested_at',
    sortable: false,
  },
  {
    Header: prefixT('rowTime'),
    accessor: 'calculation_time',
    sortable: false,
  },
  {
    Header: 'download',
    renderCell: cell => {
      const { completed_at, uid } = cell.row.original;

      if (!completed_at) {
        return (
          <div
            className="d-flex align-items-center justify-content-start"
            style={{ height: '100%' }}
          >
            <span
              className="spinner-border text-primary"
              role="status"
              aria-hidden="true"
              style={{
                width: '1.5rem',
                height: '1.5rem',
              }}
            />
          </div>
        );
      }

      return (
        <div
          className="d-flex align-items-center justify-content-start"
          style={{
            height: '100%',
          }}
        >
          <SaveIcon
            role="button"
            onClick={async () => {
              try {
                const response = await ReportService.getDownloadLink(uid);
                if (!response || !response.url) {
                  throw new Error('Invalid download URL');
                }
                const { url } = response;
                window.open(url, '_self');
              } catch (error) {
                toastr.error(prefixT('downloadFailure'));
              }
            }}
            title="Download"
            style={{
              width: '1.75rem',
              height: '1.75rem',
              cursor: 'pointer',
              fill: '#0d6efd',
            }}
          />
        </div>
      );
    },
    sortable: false,
  },
];
