import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import TourAppWrapper from 'pages/Locations/PageComponents/Tour/TourAppWrapper';

import * as route from 'constants/routes';
import Dashboard from 'pages/Dashboard/dashboard';
import { ProtectedRoute } from 'components/common/ProtectedRoute';
import { autoLogin } from 'redux/auth/actions';
import {
  userLoggingSelector,
  superAdminSelector,
  brandSelector,
  roleSelector,
  isOwnerPortalUserSelector,
} from 'redux/auth/selectors';
import {
  ProductPage,
  CreateProductPage,
  RetailersPage,
  RegionsPage,
  TiersPage,
  ProductGroupsPage,
  BrandsPage,
  EditProductPage,
  AdminsPage,
  EditThemePage,
  BrandContentPage,
  CreateBrandContentPage,
  EditBrandContentPage,
  StylistPage,
  MyLocationsPage,
  PreviewLocationPage,
  LandingPage,
  ManageWelcomeScreen,
  LearningCenter,
  Terms,
  retailerDetailsPage,
  LearningCentersList,
  LooksPage,
  EditLookPage,
  AddProducts,
  FeaturedArtistPage,
  TemplateLookPage,
  ProductCatalogPage,
  FavouriteProductsPage,
  ShareableLinksPage,
  CreateSupportLookPage,
} from 'pages';
import Layout from 'components/Layout/layout';
import { LoginRoute } from 'components/common/LoginRoute';
import PageNotFound from 'pages/404/PageNotFound';
import Login from 'pages/Login/adminToolLogin';
import AvedaLogin from 'pages/Login/avedaLogin';
import RegisterPage from 'pages/Register/registerPage';
import entities from 'constants/entities';
import AuthyAuthentication from 'pages/Login/AuthyAuthentication';
import RefillAccount from 'pages/Login/RefillAccount';
import AvedaRegisterPage from 'pages/Register/avedaRegisterPage';
import ForgetPassword from 'pages/Login/ForgetPassword';
import QRCodePage from 'pages/QRCodePage/QRCodePage';
import UserProfile from 'containers/UserProfile/userProfile';
import WelcomeScreenPage from 'pages/ManageWelcomeScreen/WelcomeScreenPage';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { OWNER, MANAGER, BRAND_ADMIN, SUPER_ADMIN, ADMIN } from 'constants/roles';
import { getAvailableProducts } from 'redux/looks/actions';
import { getLocationGroup } from 'redux/locations/actions';
import OwnerGroupPage from 'pages/OwnerGroupPage/OwnerGroupPage';
import EditTemplatePage from 'pages/Looks/pages/Template/EditTemplate';
import { useJIRAIssueCollector } from 'hooks/useJIRAIssueCollector';
import * as Widgets from 'pages/Widgets';
import { isArtistsTab, isOwnerGroupPage } from '../../utils/featureFlags.ts';
import { GlobalStyle } from './styles';
import ReportsPage from '../../pages/Reports/ReportsPage';

const getFaviconByBrand = brandPreview => {
  switch (brandPreview) {
    case 'AVEDA':
      return 'avedaFavicon.ico';
    case 'MAC':
      return 'macFavicon.ico';
    default:
      return 'favicon.ico';
  }
};

const changeFavicon = brandPreview => {
  const favicon = document.getElementById('favicon');
  favicon.href = getFaviconByBrand(brandPreview);
};

const App = () => {
  const dispatch = useDispatch();
  const isUserLogging = useSelector(userLoggingSelector);
  const isSuperAdmin = useSelector(superAdminSelector);
  const isOwnerOrManager = useSelector(isOwnerPortalUserSelector);
  const brand = useSelector(brandSelector);
  changeFavicon(brand?.preview);

  useEffect(() => {
    dispatch(autoLogin());
  }, []);

  useEffect(() => {
    if (isOwnerOrManager) {
      dispatch(getLocationGroup());
    }
  }, [isOwnerOrManager]);

  if (isUserLogging) return null;
  return (
    <ThemeProvider theme={{ isOwnerPortal: isOwnerOrManager }}>
      <TourAppWrapper>
        <Switch>
          <LoginRoute exact path={route.ADMIN_TOOL_LOGIN} component={Login} />
          <LoginRoute exact path={route.AVEDA_LOGIN} component={AvedaLogin} />
          <LoginRoute exact path={route.REGISTER} component={RegisterPage} />
          <LoginRoute exact path={route.AUTH} component={AuthyAuthentication} />
          <LoginRoute exact path={route.ADD_NUMBER} component={RefillAccount} />
          <LoginRoute exact path={route.AVEDA_REGISTER} component={AvedaRegisterPage} />
          <Route exact path={route.FORGET_PASSWORD} component={ForgetPassword} />
          <Route exact path={route.QRCODE} component={QRCodePage} />
          <Route exact path={route.WIDGET_TRENDING_LOOKS} component={Widgets.TrendingLooksWidget} />
          <Route exact path={route.WIDGET_NEW_LOOKS} component={Widgets.NewLooksWidget} />
          <Route
            exact
            path={route.WIDGET_FEATURED_ARTISTS}
            component={Widgets.FeaturedArtistsWidget}
          />
          <Route exact path="/not_found" component={PageNotFound} />
          <ProtectedRoutes isSuperAdmin={isSuperAdmin} />
        </Switch>
      </TourAppWrapper>
      <GlobalStyle />
    </ThemeProvider>
  );
};

const ProtectedRoutes = ({ location, isSuperAdmin }) => {
  const role = useSelector(roleSelector);
  const isOwnerPortalUser = [OWNER, MANAGER].includes(role);
  const dispatch = useDispatch();
  useJIRAIssueCollector(!isOwnerPortalUser);

  useEffect(() => {
    dispatch(getAvailableProducts()); // dispatch it in this place so there is no need to fetch products each time user moves to looks
  }, []);

  return (
    <Layout>
      <Suspense fallback={null}>
        <Switch>
          <Route exact path={route.TERMS} entity="terms" component={Terms} />
          <ProtectedRoute exact path={route.DASHBOARD} component={Dashboard} />
          <ProtectedRoute exact path={route.PROFILE} component={UserProfile} />
          <ProtectedRoute exact path={route.LANDING_PAGE} component={LandingPage} />
          <ProtectedRoute exact path={route.WIDGETS} component={Widgets.AdminPreview} />
          <ProtectedRoute
            exact
            entity={entities.product}
            path={route.PRODUCTS}
            component={ProductPage}
          />
          <ProtectedRoute
            exact
            entity={entities.product}
            path={route.EDIT_PRODUCTS}
            component={EditProductPage}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            exact
            entity={entities.product}
            path={route.CREATE_PRODUCT}
            component={CreateProductPage}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            exact
            entity={entities.product_group}
            path={route.PRODUCTS_GROUPS}
            component={ProductGroupsPage}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            exact
            entity={entities.retailer}
            path={route.RETAILERS}
            component={RetailersPage}
          />
          <ProtectedRoute
            entity={entities.retailer}
            path={route.SELLER}
            component={retailerDetailsPage}
          />
          <ProtectedRoute
            exact
            entity={entities.region}
            path={route.REGIONS}
            component={RegionsPage}
            condition={[BRAND_ADMIN, SUPER_ADMIN].includes(role)}
          />
          {isOwnerGroupPage() && (
            <ProtectedRoute
              exact
              path={route.OWNER_GROUP_PAGE}
              component={OwnerGroupPage}
              condition={[MANAGER].includes(role)}
            />
          )}
          <ProtectedRoute exact entity={entities.tier} path={route.TIERS} component={TiersPage} />
          <ProtectedRoute
            exact
            entity={entities.brand}
            path={route.BRAND}
            component={BrandsPage}
            condition={isSuperAdmin}
          />
          <ProtectedRoute
            exact
            entity={entities.brand_content}
            path={route.BRAND_CONTENT}
            component={BrandContentPage}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            exact
            entity={entities.brand_content}
            path={route.CREATE_BRAND_CONTENT}
            component={CreateBrandContentPage}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            exact
            entity={entities.brand_content}
            path={route.EDIT_BRAND_CONTENT}
            component={EditBrandContentPage}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            exact
            entity={entities.featured_artist}
            path={route.FEATURED_ARTIST}
            component={FeaturedArtistPage}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            exact
            entity={entities.product}
            path={route.EDIT_THEME}
            component={EditThemePage}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            exact
            entity={entities.admin}
            path={route.ADMINS}
            component={AdminsPage}
            condition={isSuperAdmin}
          />
          <ProtectedRoute
            exact
            entity={entities.salon}
            path={route.MY_LOCATIONS}
            component={MyLocationsPage}
          />
          <ProtectedRoute
            exact
            path={route.MY_LOCATIONS_SUPPORT_LOOK_CREATE}
            component={CreateSupportLookPage}
            condition={[ADMIN, BRAND_ADMIN].includes(role)}
          />
          <ProtectedRoute
            path={route.MANAGE_STYLISTS}
            component={StylistPage}
            condition={isArtistsTab()}
          />
          <ProtectedRoute
            entity={entities.salon}
            path={route.MY_LOCATIONS_PREVIEW}
            component={PreviewLocationPage}
          />
          <ProtectedRoute
            path={route.MANAGE_WELCOME_SCREEN}
            component={ManageWelcomeScreen}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            path={route.WELCOME_SCREENS}
            component={WelcomeScreenPage}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            path={route.LEARNING_CENTER}
            component={LearningCenter}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            path={route.LEARNING_CENTERS}
            component={LearningCentersList}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            path={route.LOOKS}
            component={LooksPage}
            condition={[OWNER, MANAGER].includes(role)}
          />
          <ProtectedRoute
            exact
            path={route.LOOK_EDIT}
            component={EditLookPage}
            // condition={role === OWNER}
          />
          <ProtectedRoute
            exact
            path={route.LOOK_TEMPLATE}
            component={TemplateLookPage}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            exact
            path={route.EDIT_LOOK_TEMPLATE}
            component={EditTemplatePage}
            condition={!isSuperAdmin}
          />
          <ProtectedRoute
            exact
            path={route.LOOK_ADD_PRODUCTS}
            component={AddProducts}
            condition={role === OWNER}
          />
          <ProtectedRoute
            exact
            path={route.PRODUCT_CATALOG}
            component={ProductCatalogPage}
            condition={[ADMIN, BRAND_ADMIN].includes(role)}
          />
          <ProtectedRoute
            exact
            path={route.FAVOURITE_PRODUCTS}
            component={FavouriteProductsPage}
            condition={[OWNER, MANAGER].includes(role)}
          />
          <ProtectedRoute
            exact
            path={route.GENERATE_REPORTS}
            component={ReportsPage}
            condition={[BRAND_ADMIN, SUPER_ADMIN, ADMIN].includes(role)}
          />

          <ProtectedRoute exact path={route.SHAREABLE_LINKS} component={ShareableLinksPage} />

          <Redirect
            to={{
              pathname: '/not_found', // eslint-disable-next-line
              state: { from: location },
            }}
          />
        </Switch>
      </Suspense>
    </Layout>
  );
};

ProtectedRoutes.propTypes = {
  location: PropTypes.string.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
};

export default App;
