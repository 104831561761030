import React, { useEffect, useReducer, useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Table } from '../../components';
import ReportService from '../../api/services/ReportService';
import RequestReportModal from './RequestReportModal';
import { reportsTableColumns, statusTableColumns } from './tableConfig';

const ReportsPage = ({ showReportsHistory = true }) => {
  const { t: prefixT } = useTranslation('ownerPortal', {
    keyPrefix: 'reports',
  });

  // Reducer
  const reducer = (state, { type, payload }) => {
    switch (type) {
      case 'set_reports':
        return { ...state, reports: payload, reportsLoaded: true };
      case 'set_reports_requests':
        return {
          ...state,
          reportsHistory: payload,
          reportsRequestsLoaded: true,
        };
      default:
        return state;
    }
  };

  const initialState = {
    reports: [],
    reportsLoaded: false,
    reportsHistory: [],
    reportsRequestsLoaded: false,
  };

  // State hooks
  const [state, dispatch] = useReducer(reducer, initialState);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedReport: null,
  });

  const [shouldPoll, setShouldPoll] = useState(false);
  const pollingRef = useRef(null); // Polling interval reference

  const formatTime = timeStr => {
    const date = new Date(timeStr);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const formatIdentifierToTitle = input => {
    let formattedString = input?.replace(/_/g, ' ').toLowerCase();
    formattedString = formattedString?.replace(/\b\w/g, letter => letter.toUpperCase());
    return formattedString;
  };

  const mapMinutesToString = calculationTime => {
    const seconds = Math.round(calculationTime * 60);

    if (seconds < 60) {
      return 'less than a minute';
    }

    const minutes = Math.round(calculationTime);
    return `${minutes} minutes`;
  };

  // Fetch reports
  const fetchReports = () => {
    ReportService.getAvailableReports().then(response => {
      const updatedReports = response.map(report => ({
        ...report,
        last_request: report.last_request
          ? `last requested ${formatTime(report.last_request)}`
          : prefixT('reportNotRunning'),
        report_name_formated: formatIdentifierToTitle(report.name),
      }));
      dispatch({ type: 'set_reports', payload: updatedReports });
    });
  };

  const fetchRequests = () => {
    ReportService.getReportsRequests().then(response => {
      const updatedRequests = response.map(report => ({
        ...report,
        completed_at: report.completed_at ? formatTime(report.completed_at) : null,
        requested_at: report.requested_at ? formatTime(report.requested_at) : null,
        report_name_formated: formatIdentifierToTitle(report.report_title),
        calculation_time: report.calculation_time
          ? mapMinutesToString(report.calculation_time)
          : null,
      }));
      dispatch({ type: 'set_reports_requests', payload: updatedRequests });
    });
  };

  // Open modal
  const openModal = selectedReport => {
    setModalData({
      isOpen: true,
      selectedReport,
    });
  };

  // Close modal
  const closeModal = () => {
    setModalData({
      isOpen: false,
      selectedReport: null,
    });
  };

  // Polling effect
  useEffect(() => {
    if (shouldPoll) {
      pollingRef.current = setInterval(fetchRequests, 5000);
      return () => clearInterval(pollingRef.current);
    }
  }, [shouldPoll]);

  // Initial data fetch
  useEffect(() => {
    fetchReports();
    fetchRequests();
  }, []);

  // Update polling state based on incomplete reports
  useEffect(() => {
    const hasIncompleteReports = state.reportsHistory.some(report => !report.completed_at);
    setShouldPoll(hasIncompleteReports);
  }, [state.reportsHistory]);

  return (
    <>
      <RequestReportModal
        isOpen={modalData.isOpen}
        selectedReport={modalData.selectedReport}
        onClose={closeModal}
        refetchReports={fetchReports}
        refetchRequests={fetchRequests}
      />

      <Container className="mt-5">
        <Table
          data={state.reports}
          loading={!state.reportsLoaded}
          columns={reportsTableColumns(prefixT)}
        >
          <Table.Header title={prefixT('availableReports')} />
          <Table.Content
            deletable={false}
            onRowClick={selectedRow => {
              const selectedReport = state.reports.find(report => report.id === selectedRow.id);
              openModal(selectedReport);
            }}
          />
        </Table>

        {showReportsHistory && (
          <Table
            data={state.reportsHistory}
            loading={!state.reportsRequestsLoaded}
            columns={statusTableColumns(prefixT)}
          >
            <Table.Header title={prefixT('deliveredReports')} />
            <Table.Content deletable={false} />
          </Table>
        )}
      </Container>
    </>
  );
};

ReportsPage.propTypes = {
  showReportsHistory: PropTypes.bool,
};

export default ReportsPage;
